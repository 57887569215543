export const API_Routes = {
  AUTH: {
    LOGIN: 'admin/auth/login',
    FORGOT_PWD: 'admin/auth/forgot-password',
    RESET_PWD: 'admin/auth/reset-password',
    OTP_LOGIN: 'auth/login?type=otp',
  },
  ROLES: {
    ADD: 'admin/roles/role',
    GET: 'admin/roles',
    LIST: 'auth/roles',
    GET_LAST_POSITION: 'auth/roles/last-sequence',
    GET_ALL_ROLES: 'admin/roles/rolesForUpdate',
    GET_USER_BY_ROLE: 'admin/admin_user/getUserByRoleId',
    UPDATE_USER_ROLE: 'admin/admin_user/userRoleUpdate'
  },
  PERMISSIONS: {
    GET: 'auth/permissions',
    UPDATE: 'admin/role_permission/update',
  },
  COUNTRY: {
    LIST: 'admin/country/list',
    ADD: 'admin/country',
    UPDATE: 'admin/role_permission/update',
    DELETE: 'admin/country/',
    STATUS: 'admin/country/active_status'
  },
  STATE: {
    ADD: 'admin/state',
    UPDATE: 'admin/state/state_city',
    DELETE: 'admin/state/',
    LIST: 'admin/state/list'
  },
  PROFILE: {
    // GET: 'agg/users'
    GET: 'admin/auth/account',
    PASS: 'admin/auth/change-password',
    VERIFY: 'auth/email-verify',
  },
  USERS: {
    ADD: 'agg/users',
    LIST: 'agg/users',
    GET_COUNTRY: 'users/countries',
    UPDATE: 'agg/users',
    EXPORT: 'agg/users/export',
  },
  Vehicle: {
    ENUMS: 'admin/vehicle_model',
    TYPE: 'admin/vehicle_type/vehicle',
    PATCH_TYPE: 'admin/vehicle_type',

    COLORS: 'admin/colour',
    ADD_CAR: 'users/profiles/add-car',
    UPDATE_CAR: 'users/profiles/update-car',
    RC_ADD: 'users/profiles/update-rc',
    DELETE_CAR: 'users/profiles/delete-vehicle',
    LIST: 'setting/enum/vehicles/vehicleList',

    BRANDLIST: 'admin/vehicle_brand',

    TYPELIST: 'setting/enum/vehicles/types',
    MODELLIST: 'setting/enum/vehicles/models',
    VEHICLE_TYPE_LIST: 'setting/enum/vehicles/brandsTypes',
    DELETE_IMAGE: 'users/profiles/remove_document',
  },
  CATEGORY: {
    ADD: 'setting/enum/categories',
    UPDATE: 'setting/enum/categories',
    LIST: 'setting/enum/categories',
  },
  TERMS_PAGES: {
    LIST: 'admin/informatives',
  },
  DASHBOARD: {
    LIST: 'agg/dashboard',
    GRAPH: 'agg/dashboard/graph_data'
  },
  QUESTIONS: 'admin/document_questionnaire',
  POSITION: 'admin/document_questionnaire/latest_order',

  Driver: {
    DETAIL: 'agg/users/get-car',
    DOCUMENT: 'users/profiles/get-doc',
    ADD_DOCUMENT: 'users/profiles/add-doc',
    UPDATE_DOCUMENT: 'users/profiles/update-doc',
    RC_STATUS: 'agg/users/profiles/update-rc-status',
    DOC_STATUS: 'agg/users/profiles/update-doc-status',
    Profile_Status: 'users/profiles/update-profile-status',
    USER_DETAILS_STATUS: 'agg/users/update-role-status',
    DELETE_DOCUMENT: 'users/profiles/delete-doc',
    ADD_BANK_ACCOUNT: 'users/profiles/add-user-bank-details',
    BANK_DETIALS_LIST: 'users/profiles/get_user_bank_details_list',
    DELETE_BANK_DETIALS: 'users/profiles/delete_user_bank_details',
    UPDATE_BANK_DETIALS: 'users/profiles/update_user_bank_details',
    USER_RATING_LIST: 'users/rating-reviews',
  },
  // Offers: {
  //   GET: 'agg/users/rides',
  //   GETBYID: 'agg/users/rides',
  //   STATUS: 'users/rides',
  //   EDIT: 'users/rides/update',
  // },
  Mobile: {
    GET_COUNTRIES: 'admin/country/all',
    GET_CITIES: 'admin/city',
    GET_STATES: 'admin/state',
    GET_CItY_BY_STATE: 'admin/city/byState',
    RATE_DISTANCE: 'setting/rate_distances',
    PEAK_HOURS: 'setting/peak_hours',
    LIST: 'setting/mobile_settings',
    ADD: 'setting/mobile_settings',
  },
  Bookings: {
    GET: 'users/bookings',
    GET_BOOKING_BY_ID: 'agg/booking/manage/bookings/details',
    EDIT: 'agg/booking/update_booking',
    STATUS: 'agg/ride/status'
  },
  Refund: {
    GET: 'agg/users/bookings/refund',
    GET_BY_ID: 'agg/users/bookings/refund',
    REFUNDSTATUS: 'payment/payments/is_approved',
  },
  Report: {
    GET: 'agg/spamReport',
    STATUS: 'auth/accounts',
    WARNING: 'users/rides/report',
  },
  ExportReports: {
    GET: 'agg/users/reports',
    LIST: 'agg/report/commision_report',
    PASSENGER_REPORT: 'agg/users/passenger_reports',
    DRIVER_PENDING_AMOUNT: 'agg/report/commision_report',
    WALLET_HISTORY: 'users/wallet/histories/list',
    DRIVER_COMMISSION: 'agg/report/commision_report',
    EXPORT_FILE : 'agg/report/commision_report_export',
    GET_EARNING_DRIVER : 'driver/drivers/allusers',
    GET_EARNING_PASSENGER : 'passenger/passenger/allusers',
    GET_EARNING_REPORT : 'agg/report/earning_payout_report',
    DOWNLOAD_REPORTS : 'agg/report/earning_payout_report_export',
    GET_WALLET_REPORTS : 'agg/report/wallet-report',
    DOWNLOAD_WALLET_EXPORT: 'agg/report/wallet_report_export'
  },
  COMPLAINTS : {
    GET: 'agg/complaint/all',
    COMPLAIN_DETAIL: 'agg/complaint',
    COMPLAIN_EDIT: 'admin/complaint/update',
    UPDATE_STATUS: 'admin/complaint/toggle-status',
    HISTORY_LIST: 'agg/complaint/complaint-history',
    WARNING : 'agg/complaint/warning',
    CHECK_RIDE_STATUS: 'agg/complaint/check-status'
  },
  COMMISSION: {
    LIST: 'admin/commissions/commission',
    GET: 'admin/commissions',
  },
  COUPONS: {
    LIST: 'admin/coupons',
  },
  NOTIFICATION: {
    GET: 'users/notification_settings/',
  },
  FINANCIALYEAR: {
    // LIST: 'agg/users/financial-year',
    LIST: 'admin/finances/finance',
    // ADD: 'payment/financials'
    ADD: 'admin/finances/finance',
    PATCH: 'admin/finances',
    DELETE: 'admin/finances',
  },

  // csp end points
  DOCUMENT: {
    LIST: 'admin/document_Type/type',
    UPDATE: 'admin/document_Type',
    LATESTORDER: 'admin/document_questionnaire/latest_order',
    GETBYID: 'admin/document_type',
  },
  QUESTION: {
    LIST: 'admin/document_questionnaire',
  },
  EVPLICENCE: {
    LIST: 'admin/vehicle_evp/list',
    ADD: 'admin/vehicle_evp/add',
    UPDATE: 'admin/vehicle_evp',
    GETBYID: 'admin/vehicle_evp/evpdetail',
    STATUSUPDATE: 'admin/vehicle_evp/updateStatus',
    LICENCELIST: 'driver/vehicle/admin/license_plate_numbers'
  },
  USER: {
    ADD: 'admin/admin_user',
  },
  DRIVER: {
    LIST: 'driver/drivers',
    ALL_LIST: 'driver/drivers/all_list',
    APPROV_DRIVER: 'drivers',
    // GET_VEHICLE: 'agg/vehicle',
    PATCH: 'driver/drivers/update_driver_profile',
    ADD_VEHICLE: 'agg/vehicle',
    VEHICLE_STATUS: 'driver/vehicle',
    DELETE_VEHICLE: 'agg/vehicle',
    ADD: 'driver/auth/signup',
    ACCOUNT_STATUS: 'driver/drivers/status',
    BLOCK_DRIVER: 'driver/drivers/account_status',

    APPROVAL_STATUS: 'agg/driver/change_profile_status',

    VEHICLE: 'agg/driver/vehicle',
    POST_DOCUMENT: 'driver/doc_answer',
    POST_PASSENGER_DOCUMENT:'passenger/doc_answer/',
    DOCUMENT_LIST: 'agg/driver/document',
    POST_GET_DOC_ANS: 'agg/driver/doc/answers',
    POST_GET_PASSENGER_DOC_ANS: 'agg/passenger/doc/answers',
    PATCH_DOC_APPROVE: 'driver/doc_approve/',
    PATCH_PASSENGER_DOC_APPROVE: 'passenger/doc_approve/',
    POST_DOC_IMAGE: 'driver/doc_answer/image',
    POST_PASSENGER_DOC_IMAGE: 'passenger/doc_answer/image',
    USER_RATING_LIST: 'agg/rating/getRatings',
  },
  PASSENGER: {
    // ADD: 'passenger/passenger/signup',
    ADD: 'agg/passenger/signup',
    LIST: 'passenger/passenger',
    BLOCK_DRIVER: 'passenger/passenger/account_status',
    GET_DOCUMENT_LIST: 'agg/passenger/kyc'
  },
  PERMISSION: {
    GET: 'admin/role_permission',
  },
  LINK: {
    ADD: 'admin/social_link',
  },
  EmailSettings: {
    GET: 'admin/email_setting',
  },
  THEME: {
    GET: 'auth/setting',

    LIST: 'admin/theme_settings/theme',
    GET_BY_ID: 'admin/theme_settings',
  },
  PRICE_CATELOGUE: {
    POST: 'admin/price_catalogue',
    POST_V2: 'admin/price_catalogue/v3',
    PATCH_TOGGLE: 'admin/price_catalogue/status_toggle',

    RATE_DISTANCE: 'admin/rate_distance',
    PEAK_HOURS: 'admin/peak_hour',

    ARCHIVE_LIST: 'admin/price_catalogue/archive/list',
    ARCHIVE_BY_ID: 'admin/price_catalogue/archive',
  },
  SEND_NOTIFICATION: {
    GET: 'users/notification_message/',
    LIST: 'users/notification_message',
    ADD: 'users/notification_message',
    DELETE: 'users/notification_message',
    ADD_NOTIFICATION_FOR_SELECTED_USER: 'admin/notification/add',
    SEND_NOTIFY_LIST: 'admin/notification/all-notifications',
    GET_NOTIFY_BY_ID: 'agg/notification',
    DELETE_NOTIFY_BY_ID: 'admin/notification'
  },
  HEADER: {
    BELL: 'users/notification/bell',
    NOTIFY: 'notifications',
    READ: 'users/notification/is_read',
  },
  RIDE: {
    GET_ALL: 'agg/ride/getAllRides/admin',
    GET_ALL_BOOKINGS: 'agg/booking/manage/bookings',
    GET_BY_ID: 'agg/ride/getRideDetails',
    PATCH: 'agg/ride/update_ride',
    DELETE_BY_ID: 'transaction/ride',

    //   GET: 'agg/users/rides',
    //   GETBYID: 'agg/users/rides',
      STATUS: 'users/rides',
    //   EDIT: 'users/rides/update',
  },
  COMPLETERIDE: {
      GET_ALL:'agg/booking/manage/bookings/rides-ended',
      GET_BY_ID: 'agg/booking/manage/bookings/details/complete'
  }
};
