import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getAnalytics, logEvent } from '@firebase/analytics';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-application-survey',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './application-survey.component.html',
  styleUrls: ['./application-survey.component.scss'],
})
export class ApplicationSurveyComponent implements OnInit, AfterViewInit {
  public analytics: any;
  public queryParams: any;

  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    if (this.route.snapshot.queryParams) {
      console.log(this.route.snapshot.queryParams);
    }
  }

  ngAfterViewInit() {
    this.analytics = getAnalytics();
    logEvent(this.analytics, 'page_view', {
      page_title: window.document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }

  trackLink(event: string) {
    console.log(event);
    logEvent(this.analytics, 'download_from', {
      queryParams: this.queryParams,
    });
  }
}
