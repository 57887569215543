<div class="sidebar-inner">
  <ul class="list-none">
    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.DASHBOARD,true)"
      (click)="setCurrentOpenItem('')">
      <a routerLink="/panel/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="nav-link"><span class="sidebar__icon svg-black dashboard-icon">
          <i class="fa-solid fa-chart-line"></i>
        </span>
        Dashboard
      </a>
    </li>
    <!---->
    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.USERS,true) || commonService.hasPermissions(permission_keys.PASSENGER,true)
    || commonService.hasPermissions(permission_keys.DRIVERS,true)
    || commonService.hasPermissions(permission_keys.ROLES,true)">
      <a routerLinkActive="active" (click)="showSidebarUsersDrop()" class="nav-link nav-link text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'users' && !showManageUsersItems) || checkActiverRoute('panel/manage-user') || checkActiverRoute('panel/manage-user') || checkActiverRoute('/panel/manage-roles') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-users"></i></span>Users<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showManageUsersItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showManageUsersItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showManageUsersItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.USERS,true)">
          <a routerLink="/panel/manage-user/users" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles"><i class="fa-solid fa-user-secret"></i>
            </span>Admin Users
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.DRIVERS,true)">
          <a routerLink="/panel/manage-user/manage-drivers" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles"><i
                class="fa-solid fa-user-tie"></i></span>Manage Drivers
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.PASSENGER,true)">
          <a routerLink="/panel/manage-user/manage-passenger" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles"><img
                src="assets/images/svg/manage-users.svg" alt="icon" style="max-height: 20px;"></span>Manage
            Passenger
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.PASSENGER,true)">
          <a routerLink="/panel/manage-user/user-raiting" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-star-half-stroke"></i></span>User Rating
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.ROLES,true)">
          <a routerLink="/panel/manage-user/manage-roles" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('users')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-people-group"></i>
            </span>Roles Management
          </a>
        </li>
      </ul>
    </div>

    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.USERS,true) || commonService.hasPermissions(permission_keys.PASSENGER,true)
    || commonService.hasPermissions(permission_keys.DRIVERS,true)
    || commonService.hasPermissions(permission_keys.ROLES,true)">
      <a routerLinkActive="active" (click)="showSidebarRideDrop()" class="nav-link nav-link text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'ride' && !showManageRide) || checkActiverRoute('panel/manage-ride') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-car-side"></i></span>Manage Ride<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showManageRide"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showManageRide"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showManageRide" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.USERS,true)">
          <a routerLink="/panel/manage-ride/manage-offer-ride" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('ride')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-car-side"></i>
            </span> Manage Offer Ride
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.DRIVERS,true)">
          <a routerLink="/panel/manage-ride/manage-bookings" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('ride')">
            <span class="sidebar__icon svg-black text-start sidebar__roles"><i class="far fa-calendar-alt"></i></span>
            Manage Bookings
          </a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.USERS,true)">
          <a routerLink="/panel/manage-ride/manage-completed-ride" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('ride')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-car-side"></i>
            </span> Completed Ride
          </a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.USERS,true)">
          <a routerLink="/panel/manage-ride/manage-canceled-ride" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('ride')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-car-side"></i>
            </span> Cancelled Ride
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.PASSENGER,true)">
          <a routerLink="/panel/manage-ride/manage-refund-process" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('ride')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-recycle"></i></span>
            Refund Process
          </a>
        </li>
      </ul>
    </div>

    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.TRAVEL_PREFERENCES,true)"
      (click)="setCurrentOpenItem('')">
      <a routerLink="/panel/manage-category" routerLinkActive="active" class="nav-link">
        <span class="sidebar__icon text-start"><i class="fa-solid fa-list"></i></span>Travel Preferences
      </a>
    </li>

    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.COMPLAINT,true)"
    (click)="setCurrentOpenItem('')">
    <a routerLink="/panel/manage-spam-report" routerLinkActive="active" class="nav-link">
      <span class="sidebar__icon text-start"><i class="fa-solid fa-flag"></i></span>Complaint
    </a>
  </li>
    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.MANAGECOUPON,true)">
      <a routerLink="/panel/manage-coupons" routerLinkActive="active" class="nav-link">
        <span class="sidebar__icon text-start"><i class="fa-solid fa-list"></i></span>Manage Coupons
      </a>
    </li>
    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.COUNTRY,true) || commonService.hasPermissions(permission_keys.STATE,true)">
      <a routerLinkActive="active" (click)="showSidebarCountry()" class="nav-link nav-link text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'country' && !showManageRide) || checkActiverRoute('panel/manage-country-state') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-car-side"></i></span>Manage Country & State<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showManageCountry"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showManageCountry"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showManageCountry" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.COUNTRY,true)">
          <a routerLink="/panel/manage-country-state/country" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('country')">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-car-side"></i>
            </span> Manage Country
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.STATE,true)">
          <a routerLink="/panel/manage-country-state/state" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('country')">
            <span class="sidebar__icon svg-black text-start sidebar__roles"><i class="far fa-calendar-alt"></i></span>
            Manage State & City
          </a>
        </li>
      </ul>
    </div>
    <li class="nav-item"
      *ngIf="commonService.hasPermissions(permission_keys.MANAGE_DOCUMENTS,true) || commonService.hasPermissions(permission_keys.DOCUMENT_QUESTIONNAIRE,true)">
      <a routerLinkActive="active" (click)="showSidebarDocument()" class="nav-link nav-link text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'document' && !showDocumentItems) || checkActiverRoute('panel/manage-document') || checkActiverRoute('panel/manage-document/document') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-book"></i></span>Manage Documents<i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showDocumentItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showDocumentItems"></i>
      </a>
    </li>

    <div class="ps-2" id="submenu1" *ngIf="showDocumentItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav" *ngIf="commonService.hasPermissions(permission_keys.MANAGE_DOCUMENTS,true)">
        <li class="nav-item" (click)="setCurrentOpenItem('document')">
          <a routerLink="/panel/manage-document/document" routerLinkActive="active" class="nav-link text-black">
            <span class="sidebar__icon text-start"><i class="fa-solid fa-file-invoice"></i></span>Documents
          </a>
        </li>
        <li class="nav-item" (click)="setCurrentOpenItem('document')"
          *ngIf="commonService.hasPermissions(permission_keys.DOCUMENT_QUESTIONNAIRE,true)">
          <a routerLink="/panel/manage-document/questionnaire" routerLinkActive="active" class="nav-link text-black">
            <span class="sidebar__icon text-start"><i class="fa-solid fa-file-circle-question"></i></span>Questionnaire
          </a>
        </li>
      </ul>
    </div>

    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.MANAGE_VEHICLE,true)"
      (click)="setCurrentOpenItem('')">
      <a routerLink="/panel/manage-vehicle" routerLinkActive="active" class="nav-link">
        <span class="sidebar__icon text-start">
          <i class="fa-solid fa-car-side"></i></span>Manage Vehicle
      </a>
    </li>

    <li class="nav-item"
      *ngIf="commonService.hasPermissions(permission_keys.PRICE_CATALOGUE,true) || commonService.hasPermissions(permission_keys.COMMISSION,true) || commonService.hasPermissions(permission_keys.FINANCIAL_YEAR,true)">
      <a routerLinkActive="active" (click)="showSidebarPriceDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-price' && !showPriceItems) || checkActiverRoute('panel/manage-price/price-catalogue') || checkActiverRoute('panel/manage-price/manage-commision') || checkActiverRoute('panel/manage-price/financial-year') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-hand-holding-dollar"></i></span>Manage Price <i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showPriceItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showPriceItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showPriceItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.PRICE_CATALOGUE,true)">
          <a routerLink="/panel/manage-price/price-catalogue" (click)="setCurrentOpenItem('manage-price')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon">
              <i class="fa-solid fa-money-bill-1-wave"></i></span> Price Catalogue</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.COMMISSION,true)">
          <a routerLink="/panel/manage-price/manage-commision" routerLinkActive="active"
            class="nav-link text-black"><span class="sidebar__icon">
              <i class="fa-solid fa-hand-holding-dollar"></i></span> Manage Commission</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.FINANCIAL_YEAR,true)">
          <a routerLink="/panel/manage-price/financial-year" routerLinkActive="active" class="nav-link text-black"><span
              class="sidebar__icon">
              <i class="fa-solid fa-money-bill-trend-up"></i></span> Financial Year</a>
        </li>
        <!-- <li>
          <a routerLink="/panel/manage-price/set-invoice-number" (click)="setCurrentOpenItem('invoce-number')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon">
              <img src="assets/images/price-catalogue.svg" alt="" class="width-20"></span> Set Invoice Number</a>
        </li> -->
      </ul>
    </div>
    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.WALLET,true) || commonService.hasPermissions(permission_keys.EARNING,true) || commonService.hasPermissions(permission_keys.COMMISSION,true) " >
      <a routerLinkActive="active" (click)="showSidebarReportDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-reports' && !showReportItems) || checkActiverRoute('panel/manage-earning-and-payouts-report') || checkActiverRoute('panel/manage-pending-amount-report') || checkActiverRoute('panel/manage-commission-report') || checkActiverRoute('panel/manage-refund-report') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-file-lines"></i>
        </span>Reports <i class="fas fa-chevron-down ms-3 position-absolute right-15 top-15"
          *ngIf="!showReportItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showReportItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showReportItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.EARNING,true)">
          <a routerLink="/panel/manage-pending-amount-report/earnings-and-payments" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-hand-holding-dollar"></i>
            </span>Earning & Payout</a>
        </li>
        <!-- <li>
          <a routerLink="/panel/manage-pending-amount-report/pendingAmounts" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-money-bills"></i>
            </span>Pending Amount</a>
        </li> -->
        <li *ngIf="commonService.hasPermissions(permission_keys.WALLET,true)" >
          <a routerLink="/panel/manage-pending-amount-report/wallet-history" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon svg-black text-start sidebar__roles">
              <i class="fa-solid fa-wallet"></i>
            </span>Wallet Details</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.COMMISSION,true)">
          <a routerLink="/panel/manage-commission-report/commission" routerLinkActive="active"
            (click)="setCurrentOpenItem('manage-reports')" class="nav-link text-black">
            <span class="sidebar__icon text-start">
              <i class="fa-solid fa-hand-holding-dollar"></i>
            </span>Commission Report</a>
        </li>
        <!-- <li>
          <a routerLink="/panel/manage-refund-report/refund" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-reports')">
            <span class="sidebar__icon text-start">
              <i class="fa-solid fa-money-bill-transfer"></i>
            </span>Refund Report</a>
        </li> -->
      </ul>
    </div>

    <li class="nav-item"
      *ngIf="commonService.hasPermissions(permission_keys.PRIVACY_POLICY,true) || commonService.hasPermissions(permission_keys.TERMS_CONDITIONS,true)
    || commonService.hasPermissions(permission_keys.FAQ,true) || commonService.hasPermissions(permission_keys.ABOUT_US,true)  || commonService.hasPermissions(permission_keys.SUPPORT_CONTACT,true)">
      <a routerLinkActive="active" (click)="showInforDrop()" class="nav-link nav-link  text-truncate"
        [ngClass]="(currentOpenItem === 'manage-info' && !showInfoItems) || checkActiverRoute('panel/informative') ? 'active' : ''"
        data-target="#submenu1">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-circle-info"></i></span>Informative <i
          class="fas fa-chevron-down ms-3 position-absolute right-15 top-15" *ngIf="!showInfoItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showInfoItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showInfoItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.PRIVACY_POLICY,true)">
          <a routerLink="/panel/informative/privacy-policy" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-solid fa-shield-halved"></i>
            </span>Privacy Policy
          </a>
        </li>

        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.TERMS_CONDITIONS,true)">
          <a routerLink="/panel/informative/terms-conditions" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-solid fa-file-contract"></i>
            </span>Terms & Conditions
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.FAQ,true)">
          <a routerLink="/panel/informative/faq" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-regular fa-circle-question fs-6"></i> </span>FAQ
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.ABOUT_US,true)">
          <a routerLink="/panel/informative/about" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-solid fa-circle-info"></i></span>About us
          </a>
        </li>
        <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.SUPPORT_CONTACT,true)">
          <a routerLink="/panel/informative/support-and-contact" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-info')">
            <span class="sidebar__icon text-start svg-black">
              <i class="fa-solid fa-headset"></i> </span>Support & Contact
          </a>
        </li>
      </ul>
    </div>

    <li class="nav-item" *ngIf="commonService.hasPermissions(permission_keys.EVP,true)"
    (click)="setCurrentOpenItem('')">
    <a routerLink="/panel/manage-evp" routerLinkActive="active" class="nav-link">
      <span class="sidebar__icon text-start"><i class="fa-solid fa-credit-card"></i></span>EVP
    </a>
  </li>

    <li class="nav-item"
      *ngIf="commonService.hasPermissions(permission_keys.SETTINGS,true) || commonService.hasPermissions(permission_keys.EMAIL_SETTING,true) || commonService.hasPermissions(permission_keys.ACCOUNT_SETTING,true)
    || commonService.hasPermissions(permission_keys.THEME_SETTING,true) || commonService.hasPermissions(permission_keys.MANAGE_LINKS,true) || commonService.hasPermissions(permission_keys.NOTIFICATION,true)">
      <a routerLinkActive="active" (click)="showSidebarDrop()" class="nav-link nav-link  text-truncate"
        data-target="#submenu1"
        [ngClass]="(currentOpenItem === 'manage-setting' && !showInfoItems) || checkActiverRoute('panel/manage-email-settings') || checkActiverRoute('panel/manage-notification') 
        || checkActiverRoute('panel/account-setting') || checkActiverRoute('panel/manage-links') || checkActiverRoute('panel/theme-settings') ? 'active' : ''">
        <span class="sidebar__icon text-start svg-black">
          <i class="fa-solid fa-gear"></i>
        </span>Settings <i class="fas fa-chevron-down ms-3 position-absolute right-15 top-15"
          *ngIf="!showDropItems"></i>
        <i class="fas fa-chevron-up ms-3 position-absolute right-15 top-15" *ngIf="showDropItems"></i>
      </a>
    </li>
    <div class="ps-2" id="submenu1" *ngIf="showDropItems" aria-expanded="false">
      <ul class="flex-column pl-2 ms-2 nav">
        <li *ngIf="commonService.hasPermissions(permission_keys.EMAIL_SETTING,true)">
          <a routerLink="/panel/manage-setting/manage-email-settings" (click)="setCurrentOpenItem('manage-setting')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon text-start">
              <i class="fa-solid fa-envelope-open-text"></i></span>Email Settings</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.ACCOUNT_SETTING,true)">
          <a routerLink="/panel/manage-setting/account-setting" routerLinkActive="active" class="nav-link text-black"
            (click)="setCurrentOpenItem('manage-setting')">
            <span class="sidebar__icon text-start">
              <i class="fa-solid fa-user-gear"></i></span>Account Settings</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.THEME_SETTING,true)">
          <a routerLink="/panel/manage-setting/theme-settings" (click)="setCurrentOpenItem('manage-setting')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon text-start"><img
                src="assets/images/theme.svg" alt="icon" class="width-20"></span>Theme Settings</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.MANAGE_LINKS,true)">
          <a routerLink="/panel/manage-setting/manage-links" (click)="setCurrentOpenItem('manage-links')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon text-start">
              <i class="fa-solid fa-link"></i></span>Manage Links</a>
        </li>
        <li *ngIf="commonService.hasPermissions(permission_keys.NOTIFICATION,true)">
          <a routerLink="/panel/manage-setting/manage-notification" (click)="setCurrentOpenItem('manage-notification')"
            routerLinkActive="active" class="nav-link text-black"><span class="sidebar__icon text-start">
              <i class="fa-regular fa-bell"></i></span>Manage Notification</a>
        </li>
       
      </ul>
    </div>
    <li class="nav-item"  *ngIf="commonService.hasPermissions(permission_keys.SENDNOTIFICATION,true)">
      <a routerLink="/panel/send-notification" routerLinkActive="active" class="nav-link" (click)="setCurrentOpenItem('')">
        <span class="sidebar__icon text-start"><i class="fa-solid fa-bell"></i>
        </span>Send Notification
      </a>
    </li>
  </ul>
</div>