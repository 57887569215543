import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsComponent } from './shared/components/cms/cms.component';
import { VerifyEmailComponent } from './shared/components/verify-email/verify-email.component';
import { NoPermissionsFoundComponent } from './shared/components/no-permissions-found/no-permissions-found.component';
import { ApplicationSurveyComponent } from './shared/components/application-survey/application-survey.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./feature/feature.module').then((m) => m.FeatureModule),
  },
  { path: 'cms/:type', component: CmsComponent, title: 'CMS' },
  { path: 'download-application', component: ApplicationSurveyComponent, title: 'Survey' },
  { path: 'no-permissions-found', component: NoPermissionsFoundComponent, title: 'Permissions Not Found' },
  { path: 'verify-email', component: VerifyEmailComponent, title: 'Email Verification' },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
