<div class="container">
  <div class="row vertical-center">
    <div class="col-md-7 m-auto">
        <img src="assets/images/csp-image.png">
    </div>
    <div class="col-md-5 m-auto">
      <div class="row social-btns">
        <h1>Book your ride quick and simple</h1>
        <div>
          <a class="app-btn blu flex vert" (click)="trackLink('app-store')">
            <i class="fab fa-apple"></i>
            <p class="mt-3">
              Available on the <br />
              <span class="big-txt">App Store</span>
            </p>
          </a>
        </div>
        <div>
          <a class="app-btn blu flex vert" (click)="trackLink('google-store')">
            <i class="fab fa-google-play"></i>
            <p class="mt-3">
              Get it on <br />
              <span class="big-txt">Google Play</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
